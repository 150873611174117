import React from 'react'
import Lottie from 'react-lottie'
import portLogoAnimation from '../assets/lottie/white.json'


class HomePortMainLogoAnimation extends React.Component {
    
  componentDidMount() {
    //let item = this.props.dataFromParent.item;
    // const lottieContainer = document.getElementsByClassName("lottie-" + item.slug);
    // const lottieRequireFile = "require('" + item.ACFPortfolioFields.portfolioLottieSvg + "')";
    // const lottieFile = item.ACFPortfolioFields.portfolioLottieSvg;


    // // console.log(lottieFile)
    // // console.log(lottieContainer)           
    

  }

  componentWillUnmount() {
   
  }

  render() {
    
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: portLogoAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        
    }  
    //className={'project-img lottiesvg lottie-' + item.slug} id={'lottie-' + item.slug}

    return (
        <div>
            <Lottie options={defaultOptions} height={50} width={200} speed={1} />
        </div>
    );
  }
};

export default HomePortMainLogoAnimation;
