import React from 'react'
import FeaturedMedia from './FeaturedMedia'
import HomePortMainLogoAnimation from './HomePortMainLogoAnimation'

const HomePortGrid = ({ gridItems }) => {

    return (

    <section className="home-page-port main-section" data-section="port-inview">

        <div className="t-wrap">
            <div className="container container-fluid my-5 mt-0 default">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 post-content intro-section">
                        <h2 tabIndex="3">Portfolio</h2>
                        <span></span>
                        <h3 tabIndex="3">Selected pieces of work I have had the pleasure of being apart of:</h3>

                    </div>

                </div>
            </div>

            <div className="container-fluid my-5 mt-0 default">
                <div className="row">

                    <div className="col-12 p-0">
                        <div className="gridwrap" role="list">
           
                            { gridItems.slice(0, 15).map(function(item, i) {


                                if ( item.ACFPortfolioFields.showOnHomepage == "Yes" ) {

                                    if ( item.ACFPortfolioFields.homeGridLayoutSelection == "PNG" ) { 

                                        return (
                                        
    
                                            <a href={item.uri} key={item.uri} role="listitem" tabIndex="3" aria-label={item.title + " portfolio piece, press enter to view"} className="grid-item grid-1 gridhe-1" id={item.slug}>
        
                                                <div className="r-wrap">
                                                    <div className="media-wrap d-flex align-items-center flex-column justify-content-center">
                                                       
                                                        <div className="project-img">
                                                            <FeaturedMedia image={item.ACFPortfolioFields.portfolioPng} />
                                                        </div>
        
                                                        <div className="project-title">{item.title}</div>
        
                                                        <div className="project-sub">{item.ACFPortfolioFields.portfolioItemSubHeader}</div>
                                                        
                                                    </div>
                                                </div>
        
                                            </a>
                                          
                                        )
        
                                    }
                                    //Lottie
                                    else if ( item.ACFPortfolioFields.homeGridLayoutSelection == "Lottie Animation" ) {
                                                        
                                        return (
                                           
                                            <a href={item.uri} key={item.uri} className="grid-item grid-1 gridhe-1" tabIndex="3" aria-label={item.title + " portfolio piece, press enter to view"} id={item.slug}>
        
                                                <div className="r-wrap">
                                                    <div className="media-wrap d-flex align-items-center flex-column justify-content-center">
                                                    
                                                        <HomePortMainLogoAnimation dataFromParent={item} />
    
                                                        <div className="project-sub">{item.ACFPortfolioFields.portfolioItemSubHeader}</div>
                                                        
                                                    </div>
                                                </div>
    
                                            </a>
                                        )
    
                                    }

                                    else if ( item.ACFPortfolioFields.homeGridLayoutSelection == "SVG" ) {
                                     
                                                                          
                                        return (
                                           
                                            <a href={item.uri} key={item.uri} className="grid-item grid-1 gridhe-1" tabIndex="3" aria-label={item.title + " portfolio piece, press enter to view"} id={item.slug}>
        
                                                <div className="r-wrap">
                                                    <div className="media-wrap d-flex align-items-center flex-column justify-content-center">
                                                    
                                                        <div className={'project-img svg mb-4'}>
                                                           <img src={item.ACFPortfolioFields.portfolioSvg.remoteFile.publicURL} />
                                                        </div>
    
                                                        <div className="project-sub">{item.ACFPortfolioFields.portfolioItemSubHeader}</div>
                                                        
                                                    </div>
                                                </div>
    
                                            </a>
                                        )
    
                                    }

                                }


                                

                                //Else
                                
                    
                            })}
                        </div>
                    </div>

                </div>
            </div>    
           

        </div>

    </section>
     
    );
  };
  
  export default HomePortGrid;