import React from 'react'
import lottie from 'lottie-web'
import $ from 'jquery'

class HomeHeroSliderArrows extends React.Component {
    
  componentDidMount() {

    
    /* Slider Arrows */
    const lottieArrow = document.getElementsByClassName("lottiearrow");

    function loadArrowAnimation(lottieArrow) {
        var animation =  lottie.loadAnimation({
            container: lottieArrow,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: require('../assets/lottie/skip-forwards.json')
        });
    
        lottieArrow.addEventListener("mouseenter", function () {
            animation.play();
        });
        lottieArrow.addEventListener("mouseleave", function () {
            animation.goToAndStop(0,0);
            animation.pause();
    
        });
    }
    
    for (var i = 0; i < lottieArrow.length; i++) {
        loadArrowAnimation(lottieArrow[i]);
    }

  }

  componentWillUnmount() {
   
  }

  render() {

    return (
        <div className="arrow-slip"></div>
    );
  }
};

export default HomeHeroSliderArrows;
