import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const PostMeta = ({ author, title, date }) => {
  return (
    <div className="post-meta-single post-meta-single-top m-0">
      <ul className="post-meta m-0">
        {/* <li className="post-author meta-wrapper">
          <span className="meta-icon">
            <span className="screen-reader-text">Post author</span>
            <AuthorIcon />
          </span>
          <span className="meta-text">
            By{" "}

            <AniLink 
            fade 
            to={author.uri}
            duration={0.4}
            >
            {author.firstName
                ? author.lastName
                  ? author.firstName + " " + author.lastName
                  : author.firstName
                : author.name}
            </AniLink>
          </span>
        </li> */}
        <li className="post-date meta-wrapper" tabIndex="3">
          <span className="meta-icon">
            <span className="screen-reader-text">Post date</span>
            
          </span>
          <p>{date}</p>
        </li>
       
      </ul>
    </div>
  )
}

export default PostMeta
