import React from "react"
import { graphql } from "gatsby"
// import PostEntry from "../../components/post-entry"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
// import SEO from "../../components/seo"
// import NextIcon from "../../components/icons/next"
// import PreviousIcon from "../../components/icons/previous"
import PostPreview from "../components/PostPreview"
import ArchivePagination from "../components/ArchivePagination"

import HomeHeroSlider from '../components/HomeHeroSlider'
import HomeAbout from '../components/HomeAbout'
import HomePortGrid from '../components/HomePortGrid'
import HomeBlog from '../components/HomeBlog'

import FooterContact from '../components/FooterContact'

const Home = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
    },
    pageContext: { archiveType, archivePath },
  } = props

  return (
    <Layout
      bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <Seo title="Home" description="Kent Oliver's Online Portfolio" />
        <div className="scroll-wrapper">

            <HomeHeroSlider slideItems={ props.data.wpPage.ACFHomeSlider.homeSliderItems } />  

            <HomeAbout title={ props.data.wpPage.ACFHomeSlider.aboutSection.aboutTitle } text ={ props.data.wpPage.ACFHomeSlider.aboutSection.aboutDescription } />

            <HomePortGrid gridItems={ props.data.allWpPortfolio.nodes } />

            <HomeBlog blogItems={ props.data.allWpPost.nodes } />

            <FooterContact ></FooterContact>

        </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: {
          node: { databaseId: { eq: $userDatabaseId } }}
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ACFPostFields {
            postSubTitle
            titleLead
            
            topBanner {
              articleSubtitle
            }
        }
        ACFAllPostFields {
          mainColor
          secondaryColor
          mainImage {
            remoteFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
        ...PostPreviewContent
      }
      
      
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    wpPage(slug: { eq : "home" }) {
        slug
        id
        ACFHomeSlider {
            aboutSection {
              aboutDescription
              aboutTitle
              fieldGroupName
            }
            fieldGroupName
            homeSliderItems {
              backgroundType
              fieldGroupName
              gifBackground {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              gradient
              imageBackground {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              logoServiceSlide {
                fieldGroupName
                logoImage {
                  remoteFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
                serviceRelation
              }
              lottieJsonFile
              slideLayout
              solidColor
              videoBackground
            }
          }

          


    }

    allWpPortfolio {
        nodes {
          uri
          title
          content
          link
          slug
          ACFPortfolioFields {
            fieldGroupName
            gradientBackground
            homeGridLayoutSelection
            portfolioItemSubHeader
            portfolioLottieSvg
            portfolioPng {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            portfolioSvg {
              remoteFile {
                publicURL
              }
            }
            showOnHomepage
          }
        }
      }
    
  }
`

export default Home
