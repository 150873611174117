import React from 'react'
import LiquidSliderText from './LiquidSliderText'

class HomeHeroSlides extends React.Component {
    
    componentDidMount() {
  
    }

    componentWillUnmount() {
    
    }

    render() {
        console.log('the items?')
        //console.log(this.props.dataFromParent)
        let sliderItems = this.props;
        //console.log(sliderItems);

        return (
            <div className="">testing</div>
        )
      
     
    }
};

export default HomeHeroSlides;
