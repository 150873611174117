import React from 'react'
import { LiquidDistortionText } from 'react-text-fun'

class LiquidSliderText extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showNewComponent: false
        }
        
      }
      
  componentDidMount() {
    
    this.setState({ showNewComponent: true })

  }

  componentWillUnmount() {

  }

  render() {

    let sliderText = this.props;
    console.log(sliderText);
   
    if (this.state.showNewComponent) {

        return <LiquidDistortionText
        text={sliderText.props}
        //appendTo={"blotter-" + i}
        fontFamily="Raleway, sans-serif"
        fontWeight="900"
        fontStyle="italic"
        paddingLeft="20"
        paddingRight="20"
        fill="#FFF"
        fontSize={120}
        speed={0.5}
        volatility={0.02}
    
        />
   }
   return null
  }
};

export default LiquidSliderText;
