import React from 'react'
import Lottie from 'react-lottie'
import contactLottie from '../assets/lottie/white-contact.json'


const FooterContact = () => {
  
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: contactLottie,
    }      
    const accessibilityOptions = {
        loop: true,
        autoplay: true,
        animationData: contactLottie,
    }  

    return (
        <section className="contact-footer-wrap main-section">

            <a href="/contact/" className="text-wrap d-flex align-items-center flex-column justify-content-between h-100">

                <div className="top-text">
                    <div className="contact-title-wrap d-flex align-items-center justify-content-center">
                        <h1 className="mr-5">Contact</h1>
                        <Lottie isClickToPauseDisabled options={defaultOptions} height={110} width={110} speed={1} />
                        <div className="lottieaccessibility">
                            <Lottie isClickToPauseDisabled options={accessibilityOptions} height={110} width={110} speed={0} />
                        </div>
                    </div>

                    <div className="bg-k"></div>
                </div>



            </a>

            <div className="room-shape">
                <div className="wall wall-one"></div>
                <div className="wall wall-two"></div>
                <div className="wall floor"></div>
            </div>

        </section>
    )

}

  
export default FooterContact;

