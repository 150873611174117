import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import FeaturedMedia from './FeaturedMedia'
import Img from 'gatsby-image'
import HomeHeroSliderArrows from './HomeHeroSliderArrows'
import HomeHeroSlides from './HomeHeroSlides'
import LiquidSliderText from './LiquidSliderText'

import { TransitionLink, TransitionState } from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { LiquidDistortionText } from 'react-text-fun'
import lottie from 'lottie-web'


/* Blotter */

function Arrow(props) {

    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? ">" : "<";
    return (
      <a href="/" tabIndex="3" className={className} onClick={props.onClick}>
        <div className="lottiearrow"></div>
      </a>
    );
}
  
function customPaging(i) {
    return <a href="/">{i + 1}</a>;
}
  
function appendDots(dots) {
  
    return (
      <div>
        <ul> {dots} </ul>
      </div>
    );
}


const HomeHeroSlider = ({ slideItems }) => {

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 8000,
    cssEase: "linear",
    
    pauseOnHover: false,
    pauseOnDotsHover:true,
    pauseOnArrowHover:true,
    pauseOnFocus:true,
    afterChange: function(index) {
      
    }
  };

  return (

    <section className="home-slider-wrap main-section top-of-page-item" role="slider">
    <HomeHeroSliderArrows />
    <Slider className="home-slider" 
      nextArrow={<Arrow type="next" />}
      prevArrow={<Arrow type="prev" />}
      dots={true}
      fade={true}
      customPaging={customPaging}
      appendDots={appendDots}
      {...settings}
      >
     
     {/* <HomeHeroSlides props={slideItems} /> */}
      { slideItems.map(function(item, i){
        
        /* Slide Constants */
        var backgroundColor = {
          background: '' + item.solidColor + '',
        };

        /* Determine layout */

        if ( item.slideLayout == "logoService" ) {
          // console.log('slide item')
          // console.log(item)
           
            return (
            
              <div key={i} className="slide-wrap logo-layout">
                <div className="content-wrap" style={backgroundColor}>
                  <div className="img-wrap rellax" data-rellax-speed="9">
                    <FeaturedMedia image={item.logoServiceSlide.logoImage}  />
                    
                  </div>

                  <div className="text-wrap rellax" data-rellax-speed="5">
                    <h1 tabIndex="3">lets make something</h1>
                    {/* <h2 className="vishidden iR-bli">{item.logoServiceSlide.serviceRelation}</h2> */}
                    <div className="blotter-accessibility-wrap" tabIndex="3" aria-label={item.logoServiceSlide.serviceRelation}>
                      <div className="blotter-slide-wrap" id={"blotter-" + i}></div>
                    </div>
                    <div className="blotter"><h2 className="d-none">{item.logoServiceSlide.serviceRelation}</h2></div>

                    <LiquidDistortionText
                      text={item.logoServiceSlide.serviceRelation}
                      appendTo={"blotter-" + i}
                      
                      fontFamily="ImportedRalewayBlackItalic, sans-serif"
                      fontWeight="800"
                      fontStyle="italic"
                      paddingLeft="20"
                      paddingRight="20"
                      fill="#FFF"
                      fontSize={120}
                      speed={0.5}
                      volatility={0.02}
                      
                      />

                    {/* <LiquidSliderText props={item.logoServiceSlide.serviceRelation} /> */}

                  </div>
                </div>
              </div>
            
            )
        } else  {

          return (
            <div>no layouts yo</div>
          )
        }




      })}

    
    </Slider>
    </section>
  );
    


  };
  
  // NO GraphQL query
  
  export default HomeHeroSlider;