import React from 'react'
//import { Link } from 'gatsby'

import PostMeta from './PostMeta'
import PostCategories from './PostCategories'
import FeaturedMedia from './FeaturedMedia'

import TransitionLink from 'gatsby-plugin-transition-link'
import { Link } from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const PostPreview = ({ post, isLast }) => {
  // console.log('post')
  // console.log(post)
  // console.log(post.categories)

  return (
    <>

      <article
        className={`post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${post.databaseId}`} tabIndex="3" 
      >
     
        <div className="post-wrap">
          <div className="t-wrap">
            
            <div className="title-wrap">
              <h2 tabIndex="3">{ post.title }</h2>
            </div>
            
           
          </div>
          <div className="post-cat post-link">

            <div className="cat">
              <PostCategories categories={post.categories} />
            </div>
            <div className="link">
            <AniLink 
              style={{backgroundColor: post.ACFAllPostFields.mainColor, borderColor: post.ACFAllPostFields.mainColor}}
              fade 
              tabIndex="3"
              to={post.uri}
              duration={0.4}>
              <span>view blog post</span> <i class="las la-chevron-right"></i>
            </AniLink>
            </div>
          </div>

          <div className="img-wrap">
            <div className="bg-top">
                <span style={{backgroundColor: post.ACFAllPostFields.mainColor}}></span>
                <span style={{backgroundColor: post.ACFAllPostFields.mainColor}}></span>
            </div>

            <div className="bg-bottom">
                <span style={{backgroundColor: post.ACFAllPostFields.mainColor}}></span>
                <span style={{backgroundColor: post.ACFAllPostFields.mainColor}}></span>
            </div>

            <div className="post-date" style={{backgroundColor: post.ACFAllPostFields.mainColor}} tabIndex="3" aria-label={"Posted on " + post.date}>
              <span><i class="las la-calendar-day"></i></span><p>{post.date}</p>
            </div>
            <div className="featured-media-wrap">

                <div className="mask-wrap">
                    <div className="mask-blend-wrap">
                      <div className="mask mask-one"></div>
                      <div className="mask mask-two"></div>
                      <div className="mask mask-three"></div>
                    </div>
                  
                  
                </div>
                <div className="outermask mask-four"></div>
                <FeaturedMedia image={post.ACFAllPostFields.mainImage} />
            </div>
            
          </div>
        </div>


      </article>


      {!isLast && (
        <hr
          key={post.postId + "-hr"}
          className="post-separator styled-separator is-style-wide section-inner"
          aria-hidden="true"
        />
      )}

    </>
  )
}

export default PostPreview
