import React from 'react'
import Goo from 'gooey-react'

class HomeAbout extends React.Component {
    
  componentDidMount() {
    let frontEnd = document.createElement('script');
    frontEnd.src = '/src/js/about.js'; 
    frontEnd.id = 'about-script';
    document.body.appendChild(frontEnd);
  }

  componentWillUnmount() {
    document.getElementById('about-script').remove()
  }

  render() {
 
    return (

        <section className="home-page-about main-section" data-section="about-inview" aria-label="About">

            <div className="t-wrap">
                <div className="container container-fluid my-5 mt-0 default">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 post-content intro-section">
                            
                            <h2 tabIndex="3">Designer,<br /> Developer,<br /> Creator &<br /> Problem Solver</h2>
                            <span></span>
                            <h3 tabIndex="3">From design to development, I help create custom meaningful intuitive communication materials and digital experiences for small and large businesses</h3>
                            
                        </div>
                        <div className="goo-wrap">
                            <Goo intensity="weak">
                                <svg width="192" height="192">
                         
                         

                                    <g style={{ animation: 'left 10s linear infinite' }}>
                                        <circle cx="37%" cy="37%" style={{ animation: 'right 4s linear infinite' }} fill="#a516e1" r="38" />
                                        <circle cx="63%" cy="63%" fill="#6518ed" r="33" />
                                        <circle cx="27%" cy="47%" style={{ animation: 'right 6s linear infinite' }} fill="#001BFF" r="45" />
                                        <path className="st0" fill="#111" d="M96.5,0C43.2,0,0,43.2,0,96.5S43.2,193,96.5,193S193,149.8,193,96.5S149.8,0,96.5,0z M96.5,176
                                                    C52.59,176,17,140.41,17,96.5C17,52.59,52.59,17,96.5,17S176,52.59,176,96.5C176,140.41,140.41,176,96.5,176z"/>    
                                    </g>
                                </svg>

                            </Goo>
                           
                        </div>
                    </div>
                </div>
            </div>
            <canvas id="about-waves"></canvas>
        </section>
     
    );
  }
};

export default HomeAbout;
