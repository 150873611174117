import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import FeaturedMedia from './FeaturedMedia'
import Img from 'gatsby-image'
import PostCategories from './PostCategories'
import { TransitionLink, TransitionState } from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import $ from 'jquery'

function Arrow(props) {

    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? ">" : "<";
    return (
      <a href="/" className={className} onClick={props.onClick}>
        {char}
      </a>
    );
}
  
function customPaging(i ) {
    return (
        
        <a href="/">{i + 1}</a>
    );
}
  
function appendDots(dots) {
  
    return (
      <div>
          
        <ul> {dots} </ul>
      </div>
    );
}


const HomeBlog = ({ blogItems }) => {
   
    const [nav1, setNav1] = React.useState(null)
    const [nav2, setNav2] = React.useState(null)
      let slider1 = []
      let slider2 = []
    
      React.useEffect(() => {
          setNav1(slider1)
          setNav2(slider2)
      }, [slider1, slider2])

  return (

    <section className="home-blog-wrap main-section">
        <div className="t-wrap">
            <div className="container container-fluid my-5 mt-0 default">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 post-content intro-section">

                        <h2 tabIndex="3">Blog</h2>
                        <span></span>
                        <h3 tabIndex="3">Ideas, inspirations, ramblings, rants, a tid-bit of kung-fu and kitty-cats</h3>

                    </div>
                </div>
            </div>

        </div>


        <Slider className="blog-slider" 
       
        asNavFor={nav2}
        ref={slider => (slider1 = slider)}
        >
    

        { blogItems.map(function(post, i){
        // console.log('post')
        // console.log(post)

        return (
            <div key={i} className="blog-item">

                <div className="post-text">
                    <div className="container container-fluid mt-0 default h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                              

                                <h1 tabIndex="3">{post.ACFPostFields.titleLead}</h1>

                                <h2 tabIndex="3">{post.ACFPostFields.postSubTitle}</h2>

                                <h3 tabIndex="3">{post.ACFPostFields.topBanner.articleSubtitle}</h3>

                                <div className="link-wrap d-flex align-items-center flex-wrap">
                                    <div className="category-wrap">
                                        <PostCategories categories={post.categories} />
                                    </div>
                                    <AniLink 
                                    fade 
                                    to={post.uri}
                                    duration={0.4}
                                    tabIndex="3"
                                    >
                                    Read Full Article
                                    </AniLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="post-image">
                    <div className="mask-wrap">
                        <div className="mask mask-one"></div>
                        <div className="mask mask-two"></div>
                        <div className="mask mask-three"></div>
                    </div>
                    <FeaturedMedia image={post.ACFAllPostFields.mainImage} />
                </div>
                

                
            </div>
        )




        })}

        
        </Slider>
        <div className="blog-slider-nav">
            <div className="container container-fluid mt-0 default">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">

                            <Slider
                            asNavFor={nav1}
                            ref={slider => (slider2 = slider)}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                            autoplay={false}
                            >
                            { blogItems.map(function(post, i){

                            

                                return (
                                    <div key={i} className="blog-item">

                                     
                                        <div className="post-image">
                                            <div className="mask-wrap">
                                                <div className="mask mask-one"></div>
                                                <div className="mask mask-two"></div>
                                                <div className="mask mask-three"></div>
                                            </div>
                                            <FeaturedMedia image={post.ACFAllPostFields.mainImage} />
                                        </div>
                                        

                                        
                                    </div>
                                )

                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
          </div>

    </section>
  );
    


  };


  // NO GraphQL query
  
  export default HomeBlog;